import { useState } from "react";
import { Link } from "react-router-dom";
import adminicon2 from "../../assets/images/adminicon2.svg";
import adminicon6 from "../../assets/images/adminicon6.svg";
import ObjectViewer from "./ObjectViewer";

function SingleBiomarkerDoc() {
  const [path, setPath] = useState("");
  const [isCopied, setIsCopied] = useState();
  const obj = {
    message: "Biomarkers found",
    formattedData: [
      {
        biomarker_name: "LDL",
        biomarker_description:
          "LDL (Low-Density Lipoprotein) cholesterol is often referred to as 'bad' cholesterol. It is a major contributor to the development of atherosclerosis and cardiovascular diseases. Monitoring LDL levels helps assess the risk of heart disease and guides treatment strategies.",
        biomarker_long_descriptions: null,
        male_description: null,
        female_description: null,
        deviation: null,
        test_LDL: null,
        specialist: null,
        recommendation: null,
        methods_to_increase:
          "Adopting a healthy diet low in saturated and trans fats^^^Regular exercise and physical activity^^^Medications prescribed by healthcare professionals (e.g., statins)",
        methods_to_decrease: null,
        references:
          "Reference ranges sourced from Australian Medicines Handbook. AMH Pty Ltd, 2021.",
        children_male_references_range: {
          min: "1",
          max: "5",
        },
        children_female_references_range: {
          min: "1",
          max: "5",
        },
        children_male_optimal_range: {
          min: "1.2",
          max: "1.5",
        },
        children_female_optimal_range: {
          min: "1.2",
          max: "1.5",
        },
        adult_male_references_range: {
          min: "1",
          max: "5",
        },
        adult_female_references_range: {
          min: "1",
          max: "5",
        },
        adult_male_optimal_range: {
          min: "1.2",
          max: "2.5",
        },
        adult_female_optimal_range: {
          min: "1.2",
          max: "1.5",
        },
        elder_male_references_range: {
          min: "1",
          max: "5",
        },
        elder_female_references_range: {
          min: "1",
          max: "5",
        },
        elder_male_optimal_range: {
          min: "1.2",
          max: "1.5",
        },
        elder_female_optimal_range: {
          min: "1.2",
          max: "1.5",
        },
      },
    ],
  };

  const objectString = JSON.stringify(obj, null, 2);

  let url =
    "https://optimaapi.webdevelopmentsolution.com.au/public/biomarker/search?name=LDL";
  const copyToClipboard = () => {
    setIsCopied(true);
    const el = document.createElement("textarea");
    el.value = url; // Assign the URL directly without stringify
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  return (
    <div className="dashboards product-admin">
      <div className="container-1600">
        <div className="left-right-bar">
          <div className="sidebaradmin">
            <div className="my-file-upload">
              <img
                src={require("../../assets/images/optimalogo.png")}
                className="w-50"
              />
              <div className="admin-menus">
                <ul>
                  <li className={path === "/biomarkers-api" ? "active" : " "}>
                    <img src={adminicon6} />{" "}
                    <Link to="/single-biomarkers-api"> Biomarkers API</Link>
                  </li>
                  <li
                    className={
                      path === "/single-biomarkers-api" ? "active" : " "
                    }
                  >
                    <img src={adminicon2} />{" "}
                    <Link to="/biomarkers-api"> Biomarkers</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <section className="admin-side-side   pt-5">
            <div className="d-flex justify-content-between flex-column">
              <h1
                className="mean-admin-title"
                style={{ color: "var(--Slate)", fontWeight: "600" }}
              >
                Biomarkers API
              </h1>
            </div>
            <div className="row align-items-center">
              <div className="col-md-7 mb-4 mb-md-0">
                <div className="mt-4">
                  <h5 className="mb-0" style={{ color: "var(--Slate)" }}>
                    Biomarker:
                  </h5>
                  <p className="mt-2">
                    A biomarker is a measurable indicator of biological
                    processes, conditions, or responses to treatment. It can be
                    a molecule, cell, or characteristic feature that provides
                    information about health or disease. In medical laboratory
                    science, biomarkers are used to diagnose diseases, monitor
                    their progression, predict outcomes, and personalize
                    treatment. By analyzing biomarkers, healthcare professionals
                    can gain insights into physiological processes and make
                    informed decisions about patient care.
                  </p>
                </div>

                <div className="mt-4">
                  <h5 className="mb-0" style={{ color: "var(--Slate)" }}>
                    Age References for Biomarkers:
                  </h5>
                  <p className="mt-2">
                    <strong>Child (0-18 years):</strong>
                    Biomarkers in children play a crucial role in monitoring
                    growth and development, identifying congenital conditions,
                    and diagnosing pediatric diseases. They provide insights
                    into physiological changes during childhood and adolescence,
                    guiding pediatric healthcare interventions.
                  </p>
                  <p className="mt-2">
                    <strong>Adult (19-49 years):</strong>
                    Biomarkers in adults help assess overall health, detect
                    early signs of disease, and monitor chronic conditions. They
                    are essential for preventive healthcare, disease management,
                    and personalized treatment approaches tailored to adult
                    patients' needs.
                  </p>
                  <p className="mt-2">
                    <strong>Elderly (50+ years):</strong>
                    Biomarkers in the elderly are valuable for assessing
                    age-related changes, diagnosing age-associated diseases, and
                    monitoring geriatric conditions. They support comprehensive
                    geriatric assessments and enable targeted interventions to
                    promote healthy aging and improve quality of life.
                  </p>
                </div>
              </div>
              <div className="col-md-5">
                <img
                  src="https://i.pinimg.com/736x/56/d1/cb/56d1cb903347e204fa5ba367a1e706aa.jpg"
                  className="img-fluid"
                />
              </div>
            </div>

            <div className="mt-4">
              <h5 className="mb-2" style={{ color: "var(--Slate)" }}>
                Endpoint
              </h5>
              <div
                style={{
                  backgroundColor: "#dedede",
                  padding: "10px",
                  borderRadius: "6px",
                  position: "relative",
                }}
              >
                <p className="m-0">
                  <a
                    target="_blank"
                    href={url}
                    class="link-primary text-decoration-none text-dark"
                  >
                    {url}
                  </a>
                </p>
                <button
                  style={{
                    position: "absolute",
                    top: 6,
                    right: 10,
                  }}
                  onClick={copyToClipboard}
                >
                  {isCopied ? (
                    <i class="fa-solid fa-check icon"></i>
                  ) : (
                    <i className="fa-regular fa-copy icon"></i>
                  )}
                </button>
              </div>
            </div>

            <div className="mt-4">
              <h5 className="mb-0" style={{ color: "var(--Slate)" }}>
                Data Object
              </h5>
              <p className="mt-2">
                The response from the api contains a biomarker object with the
                following fields;
              </p>
              <ObjectViewer obj={obj} />
            </div>

            <div className="biomarker"></div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default SingleBiomarkerDoc;
