function QuizFamilyHistory({ updateData }) {
  return (
    <div className="titleparaquiz text-center">
      <div className="quiz-title-head titleparaquiz">
        <h1>Family History Health Conditions</h1>

        <div>
          <p>
            <p>
              {" "}
              Has anyone in your family had a history of the following symptoms?
            </p>
          </p>
        </div>

        <div className="formbtn-quiz d-flex justify-content-center">
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Neuro"
              value="Neuro"
              onClick={() => updateData(["Iron_Check"], 0)}
            />
            <label className="btn btn-outline-primary" htmlFor="Neuro">
              Neuro Disease
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Diabetes"
              value="Diabetes"
              onClick={() => updateData(["Diabetes_Hba1c_Check"], 3)}
            />
            <label className="btn btn-outline-primary" htmlFor="Diabetes">
              Diabetes 1/2
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="IBS"
              value="IBS"
              onClick={() =>
                updateData(
                  [
                    "Gut_Inflammation_Check",
                    "Gut_Health_Check",
                    "Autoimmune_Check",
                  ],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="IBS">
              IBS/ IBD
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Ulcerativecolitis"
              value="Ulcerativecolitis"
              onClick={() =>
                updateData(
                  [
                    "Gut_Inflammation_Check",
                    "Gut_Health_Check",
                    "Autoimmune_Check",
                    "Iron_Check",
                  ],
                  3
                )
              }
            />
            <label
              className="btn btn-outline-primary"
              htmlFor="Ulcerativecolitis"
            >
              Ulcerativecolitis
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Stroke"
              value="Stroke"
              onClick={() =>
                updateData(["Cardiovascular_Check_Heart_Health"], 3)
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Stroke">
              Stroke
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Anemia"
              value="Anemia"
              onClick={() => updateData(["Iron_Check"], 3)}
            />
            <label className="btn btn-outline-primary" htmlFor="Anemia">
              Anemia
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Prediabetes"
              value="Prediabetes"
              onClick={() => updateData(["Diabetes_Hba1c_Check"], 3)}
            />
            <label className="btn btn-outline-primary" htmlFor="Prediabetes">
              Prediabetes
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="cholesterol"
              value="cholesterol"
              onClick={() =>
                updateData(
                  ["Oxidised_LDL_Check", "Cardiovascular_Check_Heart_Health"],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="cholesterol">
              High cholesterol
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="FHHCAutoimmunedisorder"
              value="FHHCAutoimmunedisorder"
              onClick={() => updateData(["Autoimmune_Check"], 3)}
            />
            <label
              className="btn btn-outline-primary"
              htmlFor="FHHCAutoimmunedisorder"
            >
              Autoimmune disorder
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Cancer"
              value="Cancer"
              onClick={() => updateData(["Full_Blood_Count_Check"], 3)}
            />
            <label className="btn btn-outline-primary" htmlFor="Cancer">
              Cancer
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Chrons"
              value="Chrons"
              onClick={() =>
                updateData(
                  [
                    "Gut_Inflammation_Check",
                    "Gut_Health_Check",
                    "Autoimmune_Check",
                    "Iron_Check",
                    "Full_Blood_Count_Check",
                    "Iron_Check ",
                  ],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Chrons">
              Chrons disaese
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Eating"
              value="Eating"
              onClick={() =>
                updateData(
                  [
                    "Nutrition_Micronutrients_Check",
                    "Thyroid_Check",
                    "Iron Check",
                    "Full_Blood_Count_Check",
                    "Bone_Health_Check",
                  ],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Eating">
              Eating disorder
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="FHHCdisorder"
              value="FHHCdisorder"
              onClick={() =>
                updateData(["Cardiovascular_Check_Heart_Health"], 3)
              }
            />
            <label className="btn btn-outline-primary" htmlFor="FHHCdisorder">
              Cardiovascular disease{" "}
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="pressure"
              value="pressure"
              onClick={() =>
                updateData(
                  [
                    "Thyroid_Check",
                    "Serum_Electrolyte_Check",
                    "Renal_Check",
                    "Adrenal_Stress_Check",
                    "Cardiovascular_Check_Heart_Health",
                  ],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="pressure">
              High blood pressure
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="blood"
              value="blood"
              onClick={() => updateData(["Nil"], 0)}
            />
            <label className="btn btn-outline-primary" htmlFor="blood">
              Low blood pressure
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="FHHCHeart"
              value="FHHCHeart"
              onClick={() =>
                updateData(["Cardiovascular_Check_Heart_Health"], 3)
              }
            />
            <label className="btn btn-outline-primary" htmlFor="FHHCHeart">
              Heart Attack
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="FHHCabove"
              value="FHHCabove"
              onClick={() => updateData(["Nil"], 0)}
            />
            <label className="btn btn-outline-primary" htmlFor="FHHCabove">
              None of the above
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizFamilyHistory;
