// created at 03-01-2024
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllProducts = createAsyncThunk(
    'GET_ALL_PRODUCTS',
    async (token) => {
        try {
            let config = {
                headers: {
                    Authorization: token,
                },
            };
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/practitioner/get-all-products`,
                config);
            let data = response.data?.data;
            return data;

        } catch (err) {
            throw err
        }
    }
)

const allProductsSlice = createSlice({
    name: "ALL_PRODUCTS",
    initialState: {
        allProducts: [],
        loader: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(getAllProducts.pending, (state) => {
                state.loader = true;
            })
            .addCase(getAllProducts.fulfilled, (state, action) => {
                state.loader = false;
                state.allProducts = action.payload;
            })
            .addCase(getAllProducts.rejected, (state, action) => {
                state.loader = false;
                state.error = action.error.message;
            });

    },
});
export default allProductsSlice.reducer;
