// created at 09-01-2024
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchPractitionerPending = createAsyncThunk(
  "FETCH_PENDING_PRACTINIONER",
  async (token) => {
    let config = {
      headers: {
        Authorization: token,
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/practitioner/get-pending`,
      config
    );
    return response.data.data;
  }
);

const PractitionerPending = createSlice({
  name: "PENDING_PRACTINIONER",
  initialState: {
    loader: false,
    pendingPractitioner: [],
    unReadPrationarCount: 0,
    error: null,
  },
  reducers: {
    unReadZero: (state) => {
      state.unReadPrationarCount = 0; // all isReadTrue
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPractitionerPending.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(fetchPractitionerPending.fulfilled, (state, action) => {
        state.loader = false;
        let arr = action.payload;
        state.pendingPractitioner = arr;
        let unReadItem = arr.filter((item) => !item.isRead);
        state.unReadPrationarCount = unReadItem.length;
      })
      .addCase(fetchPractitionerPending.rejected, (state, action) => {
        state.loader = false;
        state.error = action.error.message;
      });
  },
});

export const { unReadZero } = PractitionerPending.actions;
export default PractitionerPending.reducer;
