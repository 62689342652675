const Pagination = ({ pageCount, currentPage, handlePageChange }) => {
  return (
    pageCount > 0 && (
      <div className="d-flex justify-content-between align-items-end">
        <div className="Pagination mt-4">
          <div className="nmbering d-flex gap-3">
            {pageCount < 11 ? (
              Array.from({ length: pageCount }, (_, index) => (
                <span
                  key={index}
                  className={
                    currentPage === index + 1
                      ? "active-pagination"
                      : "pginte-curser"
                  }
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </span>
              ))
            ) : currentPage < 3 || currentPage > pageCount - 2 ? (
              <>
                {[1, 2, 3].map((pageNumber) => (
                  <span
                    key={pageNumber}
                    className={
                      currentPage === pageNumber
                        ? "active-pagination"
                        : "pginte-curser"
                    }
                    onClick={() => handlePageChange(pageNumber)}
                  >
                    {pageNumber}
                  </span>
                ))}
                <span>...</span>

                {[pageCount - 2, pageCount - 1, pageCount].map((pageNumber) => (
                  <span
                    key={pageNumber}
                    className={
                      currentPage === pageNumber
                        ? "active-pagination"
                        : "pginte-curser"
                    }
                    onClick={() => handlePageChange(pageNumber)}
                  >
                    {pageNumber}
                  </span>
                ))}
              </>
            ) : (
              <>
                <span
                  className={
                    currentPage === 1 ? "active-pagination" : "pginte-curser"
                  }
                  onClick={() => handlePageChange(1)}
                >
                  {1}
                </span>
                <span>...</span>

                {[currentPage - 1, currentPage, currentPage + 1].map(
                  (pageNumber) => (
                    <span
                      key={pageNumber}
                      className={
                        currentPage === pageNumber
                          ? "active-pagination"
                          : "pginte-curser"
                      }
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </span>
                  )
                )}
                <span>...</span>

                <span
                  className={
                    currentPage === pageCount
                      ? "active-pagination"
                      : "pginte-curser"
                  }
                  onClick={() => handlePageChange(pageCount)}
                >
                  {pageCount}
                </span>
              </>
            )}
          </div>
          <div className="linepag">
            <span></span>
          </div>
        </div>
        <div className="left-right-arrow-pag">
          <span
            className="p-3"
            onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
          >
            <i
              className="fa-solid fa-angle-left"
              style={{ cursor: "pointer" }}
            ></i>
          </span>
          <span
            onClick={() =>
              currentPage < pageCount && handlePageChange(currentPage + 1)
            }
          >
            <i
              className="fa-solid fa-angle-right"
              style={{ cursor: "pointer" }}
            ></i>
          </span>
        </div>
      </div>
    )
  );
};

export default Pagination;
