import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
// COMPONENTS IMPORTS
import QuizGender from "./QuizComponents/QuizGender";
import QuizAge from "./QuizComponents/QuizAge";
import QuizConcerns from "./QuizComponents/QuizConcerns";
import QuizMaleFitness from "./QuizComponents/QuizMaleFitness";
import QuizFemaleFitness from "./QuizComponents/QuizFemaleFitness";
import QuizGut from "./QuizComponents/QuizGut";
import QuizMaleFertility from "./QuizComponents/QuizMaleFertility";
import QuizFemaleFertility from "./QuizComponents/QuizFemaleFertility";
import QuizMaleHormones from "./QuizComponents/QuizMaleHormones";
import QuizFemaleHormones from "./QuizComponents/QuizFemaleHormones";
import QuizThyroid from "./QuizComponents/QuizThyroid";
import QuizKidney from "./QuizComponents/QuizKidney";
import QuizHeart from "./QuizComponents/QuizHeart";
import QuizPhysical from "./QuizComponents/QuizPhysical";
import QuizDiet from "./QuizComponents/QuizDiet";
import QuizSmoking from "./QuizComponents/QuizSmoking";
import QuizAlcohol from "./QuizComponents/QuizAlcohol";
import QuizCurrentHealth from "./QuizComponents/QuizCurrentHealth";
import QuizFamilyHistory from "./QuizComponents/QuizFamilyHistory";
import QuizGeneralSymptoms from "./QuizComponents/QuizGeneralSymptoms";
import QuizHormonalSymptoms from "./QuizComponents/QuizHormonalSymptoms";
import QuizShortTermConditions from "./QuizComponents/QuizShortTermConditions";
import QuizSleeping from "./QuizComponents/QuizSleeping";
import QuizSpecificConcerns from "./QuizComponents/QuizSpecificConcerns";
// COMPONENTS IMPORTS

function QuizNew() {
  const [showModal, setShowModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [showModal1, setShowModal1] = useState();
  const [closeModal1, setCloseModal1] = useState();
  const [showModal2, setShowModal2] = useState();
  const [closeModal2, setCloseModal2] = useState();
  const [showModal3, setShowModal3] = useState();
  const [closeModal3, setCloseModal3] = useState();
  const [dynamicNumberCount, setDynamicNumberCount] = useState();
  const [dynamicModalCount, setDynamicModalCount] = useState();
  const [percentage, setPercentage] = useState(0);

  const [step, setStep] = useState(-1);

  const [data, setData] = useState({
    gender: "",
    age: "",
    concern: "",
    Oxidised_LDL_Check: 0,
    Cardiovascular_Check_Heart_Health: 0,
    Iron_Check: 0,
    Zinc_Check: 0,
    B_Vitamins_Check: 0,
    Hair_Loss_Check: 0,
    Liver_Function_Check: 0,
    Hormone_Imbalance_Check: 0,
    STD_Basic_Panel: 0,
    Full_Blood_Count_Check: 0,
    Kidney_Check: 0,
    Allergies_Check: 0,
    Testoerone_Check: 0,
    Thyroid_Check: 0,
    Advance_Thyroid_Check: 0,
    Sleep_Hormone_Check: 0,
    Hormone_Check: 0,
    Cortisol_Blood_Check: 0,
    Serum_Electrolyte_Check: 0,
    Adrenal_Stress_Check: 0,
    Serum_Catecholamines: 0,
    Male_Female_Hormone_Check: 0,
    Gut_Health_Check: 0,
    PCOS_Check: 0,
    Health_Man_Woman_Scan: 0,
    Erectile_Dysfunction_Check: 0,
    Adrenals_ACTH_Check: 0,
    Diabetes_Hba1c_Check: 0,
    Estrogen_and_Progesterone_Check: 0,
    Nutrition_Micronutrients_Check: 0,
    Renal_Check: 0,
    Bone_Health_Check: 0,
    Autoimmune_Check: 0,
    Gut_Inflammation_Check: 0,
    Oral_Glucose_Tolerance_Test: 0,
  });

  const navigate = useNavigate();

  const getMaxCountKeys = () => {
    let maxKeys = [];
    let maxCount = 0;

    for (const key in data) {
      if (key !== "gender" && key !== "age" && key !== "concern") {
        const count = data[key];
        if (count > maxCount && count > 0) {
          maxCount = count;
          maxKeys = [key];
        } else if (count === maxCount && count > 0) {
          maxKeys.push(key);
        }
      }
    }

    return maxKeys;
  };

  const handleData = async (newData, key) => {
    setData({ ...data, [key]: newData });
  };

  const handleBack = () => {
    if (step === 0) {
      navigate("/");
    } else {
      setStep((prevStep) => prevStep - 1);
    }
  };

  const updateData = (names, count) => {
    setData((prevState) => {
      return names.reduce(
        (updatedState, name) => {
          updatedState[name] = prevState[name] + count;
          return updatedState;
        },
        { ...prevState }
      );
    });
  };

  const goToRecommendation = (names) => {
    navigate("/RecomendedTest", {
      state: { testName: names, status: true },
    });
  };

  useEffect(() => {
    if (step == 6) {
      setShowModal(true);
      setTimeout(() => {
        setStep((prevStep) => prevStep + 1);
        setShowModal(false);
      }, 3000);
    } else if (step == 10) {
      setShowModal1(true);
      setTimeout(() => {
        setStep((prevStep) => prevStep + 1);
        setShowModal1(false);
      }, 3000);
    } else if (step == 11) {
      setShowModal2(true);
      setTimeout(() => {
        setStep((prevStep) => prevStep + 1);
        setShowModal2(false);
      }, 3000);
    } else if (step === 13) {
      setShowModal3(true);
      setDynamicModalCount(0);
      setTimeout(() => {
        setDynamicModalCount(1);

        setTimeout(() => {
          setShowModal3(false);
          setStep((prevStep) => prevStep + 1);
        }, 4000);
      }, 4000);
    } else {
      setStep((prevStep) => prevStep + 1);
    }
  }, [data]);

  useEffect(() => {
    if (step == 1) {
      setPercentage(25);
    } else if (step == 2) {
      setPercentage(50);
    } else if (step == 3) {
      setPercentage(75);
    } else if (step == 4) {
      setPercentage(77);
    } else if (step == 5) {
      setPercentage(79);
    } else if (step == 6) {
      setPercentage(81);
    } else if (step == 7) {
      setPercentage(84);
    } else if (step == 8) {
      setPercentage(86);
    } else if (step == 9) {
      setPercentage(88);
    } else if (step == 10) {
      setPercentage(90);
    } else if (step == 11) {
      setPercentage(93);
    } else if (step == 12) {
      setPercentage(94);
    } else if (step == 13) {
      setPercentage(97);
    } else if (step == -1 || step == 0) {
      setPercentage(0);
    } else {
      const maxCount = getMaxCountKeys();


      let maxCountWithSpaces = maxCount.map((productName) =>
        productName.replace(/_/g, " ")
      );

      // Check if "Cardiovascular Heart Check" exists in maxCountWithSpaces
      const cardiovascularIndex = maxCountWithSpaces.indexOf("Cardiovascular Heart Check");
      if (cardiovascularIndex !== -1) {
        // Replace "Cardiovascular Heart Check" with "Cardiovascular (Heart Check)"
        maxCountWithSpaces[cardiovascularIndex] = "Cardiovascular (Heart Check)";
      }

      goToRecommendation(maxCountWithSpaces);
    }
  }, [step]);

  return (
    <div>
      <>
        <Header />

        <section className="quizsec">
          <div className="container-1600">
            <div className="quizcontent">
              <Box sx={{ flexGrow: 1 }} className="box-bg-white relative">
                <div className="quizspan"></div>
                <div className="quizprogress text-center">
                  <h2>Your Quiz is {`${percentage}%`} Completed</h2>
                </div>
                <Paper
                  square
                  elevation={0}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: 50,
                    pl: 2,
                    bgcolor: "background.default",
                  }}
                >
                  <div className="progressbar">
                    <span style={{ width: `${percentage}%` }}>
                      {" "}
                      <i>{`${percentage}`}</i>
                    </span>
                  </div>
                </Paper>
                <div className="quiz-bk-handler quiz-new-bk">
                  <>

                    {step > 3 ? (
                      <></>
                    ) : (
                      <button onClick={handleBack} className="quiz-bk-btn">
                        {step === 0 ? "Exit" : "Back"}
                      </button>
                    )}
                  </>
                </div>
                {/* QUIZ COMPONENTS */}
                {step === 0 && (
                  <QuizGender
                    data={data}
                    handleData={handleData}
                    setData={setData}
                  />
                )}
                {step === 1 && (
                  <QuizAge
                    data={data}
                    handleData={handleData}
                    setData={setData}
                  />
                )}
                {step === 2 && (
                  <QuizConcerns
                    data={data}
                    handleData={handleData}
                    setData={setData}
                  />
                )}
                {step === 3 && data?.concern === "gut" ? (
                  <QuizGut
                    data={data}
                    setData={setData}
                    handleData={handleData}
                  />
                ) : step === 3 && data?.concern === "fitness" ? (
                  <QuizMaleFitness data={data} setData={setData} />
                ) : step == 3 &&
                  data?.concern === "fertility" &&
                  data?.gender === "Male" ? (
                  <QuizMaleFertility data={data} setData={setData} />
                ) : step == 3 &&
                  data?.concern === "fertility" &&
                  data?.gender === "Female" ? (
                  <QuizFemaleFertility data={data} setData={setData} />
                ) : step === 3 &&
                  data?.concern === "hormones" &&
                  data?.gender === "Male" ? (
                  <QuizMaleHormones data={data} setData={setData} />
                ) : step === 3 &&
                  data?.concern === "hormones" &&
                  data?.gender === "Female" ? (
                  <QuizFemaleHormones data={data} setData={setData} />
                ) : step === 3 &&
                  data?.concern === "hormones" &&
                  data?.gender === "Male" ? (
                  <QuizMaleHormones data={data} setData={setData} />
                ) : step === 3 &&
                  data?.concern === "hormones" &&
                  data?.gender === "Female" ? (
                  <QuizFemaleHormones data={data} setData={setData} />
                ) : step === 3 && data?.concern === "thyroid" ? (
                  <QuizThyroid data={data} setData={setData} />
                ) : step === 3 && data?.concern === "kidney" ? (
                  <QuizKidney data={data} setData={setData} />
                ) : step === 3 && data?.concern === "heart" ? (
                  <QuizHeart data={data} setData={setData} />
                ) : step === 3 && data?.concern === "noGoals" ? (
                  <QuizPhysical
                    data={data}
                    setData={setData}
                    updateData={updateData}
                  />
                ) : step === 4 && data?.concern === "noGoals" ? (
                  <QuizDiet
                    data={data}
                    setData={setData}
                    updateData={updateData}
                  />
                ) : step === 5 && data?.concern === "noGoals" ? (
                  <QuizSmoking
                    data={data}
                    setData={setData}
                    updateData={updateData}
                  />
                ) : step === 6 && data?.concern === "noGoals" ? (
                  <QuizAlcohol
                    data={data}
                    setData={setData}
                    updateData={updateData}
                  />
                ) : step === 7 && data?.concern === "noGoals" ? (
                  <QuizCurrentHealth
                    data={data}
                    setData={setData}
                    updateData={updateData}
                  />
                ) : step === 8 && data?.concern === "noGoals" ? (
                  <QuizFamilyHistory
                    data={data}
                    setData={setData}
                    updateData={updateData}
                  />
                ) : step === 9 && data?.concern === "noGoals" ? (
                  <QuizGeneralSymptoms
                    data={data}
                    setData={setData}
                    updateData={updateData}
                  />
                ) : step === 10 && data?.concern === "noGoals" ? (
                  <QuizHormonalSymptoms
                    data={data}
                    setData={setData}
                    updateData={updateData}
                  />
                ) : step === 11 && data?.concern === "noGoals" ? (
                  <QuizShortTermConditions
                    data={data}
                    setData={setData}
                    updateData={updateData}
                  />
                ) : step === 12 && data?.concern === "noGoals" ? (
                  <QuizSleeping
                    data={data}
                    setData={setData}
                    updateData={updateData}
                  />
                ) : step === 13 && data?.concern === "noGoals" ? (
                  <QuizSpecificConcerns
                    data={data}
                    setData={setData}
                    updateData={updateData}
                  />
                ) : (
                  <></>
                )}

                {/* QUIZ MODALS */}

                {/* QUIZ COMPONENTS */}

                <Modal
                  className="quizmodal"
                  show={showModal}
                  onHide={closeModal}
                  backdrop="static"
                  keyboard={false}
                  centered
                >
                  <Modal.Body>
                    <h1>
                      And now let's talk about what’s <br />
                      bothering you right now
                    </h1>
                    <div
                      className="progressbar"
                      style={{ width: "30%", margin: "0 auto" }}
                    >
                      <span style={{ width: `30%` }}></span>
                    </div>
                  </Modal.Body>
                </Modal>

                <Modal
                  className="quizmodaltwo"
                  show={showModal1}
                  onHide={closeModal1}
                  backdrop="static"
                  keyboard={false}
                  centered
                >
                  <Modal.Body>
                    <div className="datasavage">
                      <h1>Your data is safe</h1>
                      <div className="d-flex align-items-center">
                        <p>
                          Data security is very important to us and we never
                          share data with other companies.{" "}
                        </p>

                        <img src="https://solidificatiosolution.s3.ap-southeast-2.amazonaws.com/imgs/datasaveimg.png" />
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>

                <Modal
                  className="quizmodal"
                  show={showModal2}
                  onHide={closeModal2}
                  backdrop="static"
                  keyboard={false}
                  centered
                >
                  <Modal.Body>
                    <h1>
                      And finally, a few questions about sleep, memory and
                      attention
                    </h1>
                    <div
                      className="progressbar"
                      style={{ width: "30%", margin: "0 auto" }}
                    >
                      <span style={{ width: `30%` }}></span>
                    </div>
                  </Modal.Body>
                </Modal>

                <Modal
                  className="quizmodal"
                  show={showModal3}
                  onHide={closeModal3}
                  backdrop="static"
                  keyboard={false}
                  centered
                >
                  <Modal.Body className="nowtalkendmod">
                    <h1>Analyzing Your Profile</h1>
                    <div
                      className="progressbar"
                      style={{ width: "60%", margin: "0 auto" }}
                    >
                      <span className="customKeyFrame"></span>
                    </div>
                    <h3>{dynamicNumberCount}</h3>
                    {dynamicModalCount == 0 ? (
                      <>
                        <img src="https://solidificatiosolution.s3.ap-southeast-2.amazonaws.com/imgs/monitor.png" />
                        <div className="mt-5 nowtalkend">
                          <h4>All your health insights in one place</h4>
                        </div>
                      </>
                    ) : dynamicModalCount == 1 ? (
                      <>
                        <img src="https://solidificatiosolution.s3.ap-southeast-2.amazonaws.com/imgs/medical-checkup.png" />
                        <div className="mt-5 nowtalkend">
                          <h4>FINALISING YOUR PROFILE</h4>
                          <p>Help us select the right test for you.</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <img src="https://solidificatiosolution.s3.ap-southeast-2.amazonaws.com/imgs/medical-report.png" />
                        <div className="mt-5 nowtalkend">
                          <h4>FINALISING YOUR PROFILE</h4>
                          <p>Help us select the right test for you.</p>
                        </div>
                      </>
                    )}
                  </Modal.Body>
                </Modal>
              </Box>
            </div>
          </div>
        </section>

        <Footer />
      </>
    </div>
  );
}

export default QuizNew;
