import { useState } from "react";
import { Link } from "react-router-dom";
import { ContactForm } from "./ContactForm";

export function HelpWidget() {
  const [faqOpen, SetFaqOpen] = useState(false);

  const handleOpen = () => {
    SetFaqOpen(true);
  };
  const handleFaqClose = () => {
    SetFaqOpen(false);
  };

  return (
    <div className="askquestion">
      {!faqOpen ? (
        <button className="ask-question-btn" onClick={handleOpen}>
          <Link to="#">Ask a Question</Link>
        </button>
      ) : (
        <div className="banner-faq-form banner-faq-form-2">
          <ContactForm />
        </div>
      )}
    </div>
  );
}
