import { useNavigate } from "react-router-dom";

function QuizThyroid() {
  const navigate = useNavigate();
  const goToRecommendation = (name) => {
    let newName = "Thyroid" + name;

    navigate("/RecomendedTest", {
      state: { testName: newName },
    });
  };
  return (
    <div className="titleparaquiz text-center">
      <div className="quiz-title-head titleparaquiz">
        <h1>Thyroid</h1>
        <div>
          <p>
            <p>
              Thyroid dysfunction can come in all shapes and sizes and can
              present itself in many different ways. Do any of these apply to
              you?
            </p>
          </p>
        </div>
        <div className="formbtn-quiz d-flex justify-content-center">
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="tyweight"
              value="tyweight"
              onChange={() => goToRecommendation("WeightGain")}
            />
            <label
              className="btn btn-outline-primary text-truncate cust-text-truncate"
              htmlFor="tyweight"
            >
              Weight gain fatigue low mood/sensitivity to cold
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="tyweightloss"
              value="tyweightloss"
              onChange={() => goToRecommendation("WeightLoss")}
            />
            <label
              className="btn btn-outline-primary text-truncate cust-text-truncate"
              htmlFor="tyweightloss"
            >
              Weight loss agitation and sensitivity to heat
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="tyFamily"
              value="tyFamily"
              onChange={() => goToRecommendation("Family")}
            />
            <label className="btn btn-outline-primary" htmlFor="tyFamily">
              Family history of thyroid
            </label>
          </div>

          {/* NEW CATEGORY */}
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="tyThyroid"
              value="tyThyroid"
              onChange={() => goToRecommendation("General")}
            />
            <label className="btn btn-outline-primary" htmlFor="tyThyroid">
              General Thyroid Checks
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizThyroid;
