function QuizAlcohol({ data, setData, updateData }) {
  return (
    <div className="titleparaquiz text-center">
      <div className="quiz-title-head titleparaquiz">
        <h1>Lifestyle</h1>

        <div>
          <p>
            <p>
              Sharing information on your frequency of alcohol consumption is
              crucial to assess health risks provide tailored advice and
              determine necessary interventions for personalised care.
            </p>
          </p>
        </div>
        <label>
          <h2 style={{ color: "var(--Slate", fontWeight: "500" }}>Alcohol </h2>
        </label>
        <div className="formbtn-quiz d-flex justify-content-center">
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Never"
              value="Never"
              onClick={() =>
                updateData(
                  [
                    "Iron_Check",
                    "Zinc_Check",
                    "B_Vitamins_Check",
                    "Liver_Function_Check",
                  ],
                  0
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Never">
              Never
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="AlcoholSometimes"
              value="AlcoholSometimes"
              onClick={() =>
                updateData(
                  [
                    "Iron_Check",
                    "Zinc_Check",
                    "B_Vitamins_Check",
                    "Liver_Function_Check",
                  ],
                  1
                )
              }
            />
            <label
              className="btn btn-outline-primary"
              htmlFor="AlcoholSometimes"
            >
              Sometimes
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Often"
              value="Often"
              onClick={() =>
                updateData(
                  [
                    "Iron_Check",
                    "Zinc_Check",
                    "B_Vitamins_Check",
                    "Liver_Function_Check",
                  ],
                  2
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Often">
              Often
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizAlcohol;
