import { useLocation, useNavigate } from "react-router-dom";

function QuizMaleFitness({ data, setData }) {
  const location = useLocation();
  const navigate = useNavigate();
  const goToRecommendation = (name) => {
    let newName = data?.gender + name;

    navigate("/RecomendedTest", {
      state: { testName: newName },
    });
  };
  return (
    <div className="titleparaquiz text-center">
      <div className="quiz-title-head titleparaquiz">
        <h1>Performance (Fitness)</h1>

        <div>
          <p>
            <p>What is your main performance goals?</p>
          </p>
        </div>
        <div className="formbtn-quiz d-flex justify-content-center">
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Weight"
              value="Weight"
              onChange={() => goToRecommendation("loseWeight")}
            />
            <label className="btn btn-outline-primary" htmlFor="Weight">
              Lose Weight
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Performance"
              value="Performance"
              onChange={() => goToRecommendation("optimizePerformance")}
            />
            <label className="btn btn-outline-primary" htmlFor="Performance">
              Optimise Performance
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Strength"
              value="Strength"
              onChange={() => goToRecommendation("increaseMuscle")}
            />
            <label className="btn btn-outline-primary" htmlFor="Strength">
              Increase Muscle & Strength{" "}
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Muscle"
              value="Muscle"
              onChange={() => goToRecommendation("endurance")}
            />
            <label className="btn btn-outline-primary" htmlFor="Muscle">
              Edurance & Fitness
            </label>
          </div>
          {/* Check if this name is relevent to backend data (NEW CATEGORY */}
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Basic"
              value="Basic"
              onChange={() => goToRecommendation("general")}
            />
            <label className="btn btn-outline-primary" htmlFor="Basic">
              General Perfomance Checks
            </label>
          </div>
          {/* Check if this name is relevent to backend data (NEW CATEGORY  */}
        </div>
      </div>
    </div>
  );
}

export default QuizMaleFitness;
