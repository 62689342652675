import axios from "axios";
import { ChangeEvent, FormEvent, useState } from "react";

export function ContactForm() {
  const [error, setError] = useState(false);
  const [text, setText] = useState("");

  const [contact, setContact] = useState({
    fullname: "",
    phone: "",
    email: "",
    message: "",
  });

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setContact((prevContact) => ({
      ...prevContact,
      [name]: value,
    }));
  };
  // console.log("contact.....",contact)
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      !contact.fullname ||
      !contact.phone ||
      !contact.email ||
      !contact.message
    ) {
      setText("all feilds required");
      setError(true);
      return;
    }

    if (!validateEmail(contact.email)) {
      setText("enter a valid email address");
      setError(true);
      return;
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/customer/contactUs`,
          {
            fullname: contact.fullname,
            number: contact.phone,
            email: contact.email,
            message: contact.message,
          }
        );
        console.log("response", response);
        if (response?.status == 200) {
          setText("submitted successfully");
          setError(true);

          setTimeout(() => {
            setError(false);
          }, 1000);

          setContact({
            fullname: "",
            phone: "",
            email: "",
            message: "",
          });
        }
      } catch (error) {
        console.log("catch block", error);
        setText("Something went wrong, please try again later.");
        setError(true);
      }
    }
  };

  return (
    <form className="contact-top-form" onSubmit={handleSubmit}>
      <div className="grid-form-one">
        <div className="name-input">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            placeholder="Full Name"
            name="fullname"
            value={contact.fullname}
            onChange={handleChange}
          />
        </div>
        <div className="number-input-contact">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="number"
            name="phone"
            placeholder="Phone Number"
            value={contact.phone}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="grid-form-two">
        <div className="email-input">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            placeholder="contact@rubin.com"
            value={contact.email}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="grid-form-two">
        <div className="msg-input">
          <label htmlFor="message">Message</label>
          <textarea
            placeholder="Please describe what service you are interested in..."
            name="message"
            value={contact.message}
            onChange={handleChange}
          ></textarea>
        </div>
      </div>
      {error && <div className="prnt-error-txt py-2 m-0">{error && text}</div>}
      <div className="grid-form-three">
        <input type="submit" />
      </div>
    </form>
  );
}
