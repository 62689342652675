import { useLocation, useNavigate } from "react-router-dom";

function QuizSpecificConcerns({ updateData }) {
  const goToRecommendation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    navigate("/RecomendedTest", {
      state: { quizStep: activeStep, testName: "Comprehensive T" },
    });
  };
  return (
    <div className="titleparaquiz text-center">
      <div className="quiz-title-head titleparaquiz">
        <h1>Any Specific Concerns?</h1>

        <div>
          <p>
            <p>
              {" "}
              Do you have any specific concerns? You know your body better than
              anyone else. If there is something specific that brought you here
              please let us know.
            </p>
          </p>
        </div>
        <div className="formbtn-quiz d-flex justify-content-center">
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="ASCAnemia"
              value="ASCAnemia"
              onClick={() => updateData(["Full_Blood_Count_Check"], 100)}
            />
            <label className="btn btn-outline-primary" htmlFor="ASCAnemia">
              Anemia
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="TestosteroneLevels"
              value="TestosteroneLevels"
              // onClick={() => updateData(["Testoerone Check"], 100)}
              onClick={() => updateData(["Testoerone Check"], 100)}
            />
            <label
              className="btn btn-outline-primary"
              htmlFor="TestosteroneLevels"
            >
              Testosterone levels
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="OestrogenLevels"
              value="OestrogenLevels"
              onClick={() => updateData(["Hormone_Imbalance_Check"], 100)}
            />
            <label
              className="btn btn-outline-primary"
              htmlFor="OestrogenLevels"
            >
              Oestrogen levels
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Hairloss"
              value="Hairloss"
              onClick={() => updateData(["Hair_Loss_Check"], 100)}
            />
            <label className="btn btn-outline-primary" htmlFor="Hairloss">
              Hair loss
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="KidneyConditions"
              value="KidneyConditions"
              onClick={() => updateData(["Kidney_Check"], 100)}
            />
            <label
              className="btn btn-outline-primary"
              htmlFor="KidneyConditions"
            >
              Kidney Conditions
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Allergies"
              value="Allergies"
              onClick={() => updateData(["Allergies_Check"], 100)}
            />
            <label className="btn btn-outline-primary" htmlFor="Allergies">
              Allergies
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="ASClifeless"
              value="ASClifeless"
              onClick={() => updateData(["STD_Basic_Panel"], 100)}
            />
            <label className="btn btn-outline-primary" htmlFor="ASClifeless">
              Sexually transmitted infections
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Noneoftheabove"
              value="Noneoftheabove"
              onClick={() => updateData(["Nil"], 0)}
            />
            <label className="btn btn-outline-primary" htmlFor="Noneoftheabove">
              None of the above
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizSpecificConcerns;
