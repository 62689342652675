import { useLocation, useNavigate } from "react-router-dom";

function QuizShortTermConditions({ updateData }) {
  const goToRecommendation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    navigate("/RecomendedTest", {
      state: { quizStep: activeStep, testName: "Comprehensive T" },
    });
  };
  return (
    <div className="titleparaquiz text-center">
      <div className="quiz-title-head titleparaquiz">
        <h1>Short Term Conditions</h1>

        <div>
          <p>
            <p> Do you experience any of the following general symptoms?</p>
          </p>
        </div>
        <div className="formbtn-quiz d-flex justify-content-center">
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="STCExcessive"
              value="STCExcessive"
              onClick={() =>
                updateData(
                  [
                    "Kidney_Check",
                    "Serum_Electrolyte_Check",
                    "Serum_Catecholamines",
                    "Thyroid_Check",
                  ],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="STCExcessive">
              Frequent Headaches
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="STCIncreased"
              value="STCIncreased"
              onClick={() =>
                updateData(
                  [
                    "Iron_Check",
                    "Full_Blood_Count_Check",
                    "Serum_Electrolyte_Check",
                    "B_Vitamins_Check",
                  ],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="STCIncreased">
              Weakness
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="STCDecrease"
              value="STCDecrease"
              onClick={() =>
                updateData(
                  [
                    "Thyroid_Check",
                    "Serum_Catecholamines",
                    "Adrenal_Stress_Check",
                    "Cortisol_Blood_Check",
                  ],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="STCDecrease">
              Anxiety
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="STCdryness"
              value="STCdryness"
              onClick={() => updateData(["Nutrition_Micronutrients_Check"], 3)}
            />
            <label className="btn btn-outline-primary" htmlFor="STCdryness">
              Shredding hair or peeling nails
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="STClifeless"
              value="STClifeless"
              onClick={() => updateData(["Nil"], 0)}
            />
            <label className="btn btn-outline-primary" htmlFor="STClifeless">
              Recurrent thrush/herpes
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Swelling"
              value="Swelling"
              onClick={() => updateData(["Gut_Health_Check"], 3)}
            />
            <label className="btn btn-outline-primary" htmlFor="Swelling">
              Frequent constipation
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Irregular"
              value="Irregular"
              onClick={() => updateData(["Gut_Health_Check"], 3)}
            />
            <label className="btn btn-outline-primary" htmlFor="Irregular">
              A tendency for loose stools
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Trembling"
              value="Trembling"
              onClick={() => updateData(["Serum_Electrolyte_Check"], 3)}
            />
            <label className="btn btn-outline-primary" htmlFor="Trembling">
              Cramps
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="gain"
              value="gain"
              onClick={() => updateData(["Hormone_Imbalance_Check"], 3)}
            />
            <label className="btn btn-outline-primary" htmlFor="gain">
              Decrease sex drive
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Noneoftheabove"
              value="Noneoftheabove"
              onClick={() => updateData(["Nil"], 0)}
            />
            <label className="btn btn-outline-primary" htmlFor="Noneoftheabove">
              None of the above
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizShortTermConditions;
