import { useLocation, useNavigate } from "react-router-dom";

function QuizConcerns({ data, setData, handleData }) {
  const location = useLocation();
  const navigate = useNavigate();

  const goToRecommendation = () => {
    const name =
      data?.gender === "Male" ? "Male Health Checkup" : "Female Health Checkup";

    navigate("/RecomendedTest", {
      state: { testName: name },
    });
  };
  return (
    <div className="titleparaquiz text-center">
      <div className="quiz-title-head titleparaquiz">
        <h1>Concerns</h1>

        <div>
          <p>
            <p>
              You are obviously invested in improving your health. What was your
              main concern that you were wishing to address?
            </p>
          </p>
        </div>
        <div className="d-flex justify-content-center">
          <button onClick={goToRecommendation} className="rectestredirection">
            General Health Check
          </button>
        </div>
        <div className="formbtn-quiz d-flex justify-content-center mt-4">
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Fitness"
              value="Fitness"
              checked={data?.concern == "fitness"}
              onClick={(e) => handleData("fitness", "concern")}
            />
            <label className="btn btn-outline-primary" htmlFor="Fitness">
              Performance (Fitness)
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Hormones"
              value="Hormones"
              checked={data?.concern == "hormones"}
              onClick={(e) => handleData("hormones", "concern")}
            />
            <label className="btn btn-outline-primary" htmlFor="Hormones">
              Hormones
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Fertility"
              value="Fertility"
              checked={data?.concern == "fertility"}
              onClick={(e) => handleData("fertility", "concern")}
            />
            <label className="btn btn-outline-primary" htmlFor="Fertility">
              Reproductive (STD & Fertility)
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Thyroid"
              value="Thyroid"
              checked={data?.concern == "thyroid"}
              onClick={(e) => handleData("thyroid", "concern")}
            />
            <label className="btn btn-outline-primary" htmlFor="Thyroid">
              Thyroid
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Kidney"
              value="Kidney"
              checked={data?.concern == "kidney"}
              onClick={(e) => handleData("kidney", "concern")}
            />
            <label className="btn btn-outline-primary" htmlFor="Kidney">
              Kidney
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Heart"
              value="Heart"
              checked={data?.concern == "heart"}
              onClick={(e) => handleData("heart", "concern")}
            />
            <label className="btn btn-outline-primary" htmlFor="Heart">
              Heart
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Gut"
              value="Gut"
              checked={data?.concern == "gut"}
              onClick={(e) => handleData("gut", "concern")}
            />
            <label className="btn btn-outline-primary" htmlFor="Gut">
              Gut & Nutrition
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="goals"
              value="goals"
              checked={data?.concern == "noGoals"}
              onClick={(e) => handleData("noGoals", "concern")}
            />
            <label className="btn btn-outline-primary" htmlFor="goals">
              No goals yet
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizConcerns;
