import { CircularProgress } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { CartContext } from "../../context/Cart";
import { useNotificationContext } from "../../context/Notification";
import { encryptId, getCookie, getRole } from "../../helpers/Helpers";
import { useScrollToTop } from "../../helpers/useScrollToTop";
import Cart from "../Cart";
import { HelpWidget } from "../HelpWidget";
import styles from "./Header.module.scss";

function Header({ fixed = true }) {
  const token = getCookie();
  const role = getRole();

  const { cartItems } = useContext(CartContext);

  const { notificationCount } = useNotificationContext();

  const [cartVisible, setCartVisible] = useState(false);

  const location = useLocation();
  useScrollToTop();

  const closeCart = () => {
    setCartVisible(false);
  };
  const dropdownRef = useRef(null);
  const dropdownRef1 = useRef(null);

  useEffect(() => {
    const handleOverlayClick = (event) => {
      if (
        dropdownRef1.current &&
        !dropdownRef1.current.contains(event.target)
      ) {
        closeCart();
      }
    };

    document.addEventListener("click", handleOverlayClick);

    return () => {
      document.removeEventListener("click", handleOverlayClick);
    };
  }, []);

  const [toggleDiv, setToggleDiv] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };
  const toggleNavClose = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    const handleOverlayClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        toggleNavClose();
      }
    };

    document.addEventListener("click", handleOverlayClick);

    return () => {
      document.removeEventListener("click", handleOverlayClick);
    };
  }, []);

  // searching fahad
  const timeoutIdRef = useRef(null);
  const [isLoader, setIsLoader] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [prodPage, setProdPage] = useState(1);
  const [allProd, setAllProd] = useState([]);
  const [displayProd, setDisplayProd] = useState([]);
  const [blogs, setBlogs] = useState([]);

  const handleChangeSearch = (text) => {
    setProdPage(1);
    if (text && text.length > 0 && text !== " ") {
      setSearchVal(text);
      setIsLoader(true);
      setToggleDiv(true);
      // Clear previous timer
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }

      // Set new timer
      timeoutIdRef.current = setTimeout(async () => {
        try {
          const searchRes = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/blogs/search?query=${text}`
          );
          setBlogs(searchRes?.data?.blogs);
          setAllProd(searchRes?.data?.products);
        } catch (err) {
          console.error("ERROR", err);
        } finally {
          setIsLoader(false);
        }
      }, 1500);
    } else {
      // if search is empty
      setSearchVal("");
    }
  };

  useEffect(() => {
    setDisplayProd(allProd.slice(0, prodPage * 3));
  }, [allProd, prodPage]);

  const [searchOpen, setSearchOpen] = useState(false);
  const toggleSearch = () => setSearchOpen(!searchOpen);

  return (
    <div>
      <HelpWidget />
      <header
        id="header"
        className={fixed ? styles.fixedHeader : styles.header}
      >
        {toggleDiv && (
          <div className="search-input-open-box-parent">
            <div className="search-input-open-box-inner-parent">
              <span className="cut-search">
                <i
                  className="fa-regular fa-circle-xmark"
                  onClick={() => setToggleDiv(false)}
                ></i>
              </span>
              <div className="search-input-open-box-left d-flex flex-column">
                <h3 className="search-input-open-box-heading mb-0">Products</h3>
                {isLoader ? (
                  <div className="text-center py-2">
                    <CircularProgress style={{ color: "#4f6367" }} />
                  </div>
                ) : (
                  <div className="search-input-open-box-inner-grid">
                    {displayProd && displayProd.length > 0 ? (
                      displayProd.map((item, index) => (
                        <Link
                          to="/ProductInner"
                          state={item}
                          key={index}
                          className="search-input-open-box-inner-grid-box-parent"
                        >
                          <img
                            className="img-fluid"
                            src={`${process.env.REACT_APP_CDN_URL}/${item.primaryImagePath}`}
                            alt={item?.name}
                          />
                          <h3 className="search-result-box-heading mb-0">
                            {item?.name}
                          </h3>
                          <span className="search-result-box-price">{`$${
                            item?.salePrice
                              ? item?.salePrice
                              : item?.regularPrice
                          }`}</span>
                        </Link>
                      ))
                    ) : (
                      <h5>No Products</h5>
                    )}
                  </div>
                )}
                {allProd.length > prodPage * 3 && (
                  <button
                    onClick={() => setProdPage((prev) => prev + 1)}
                    className="viewdemo viewtest"
                    style={{ margin: "0 auto" }}
                  >
                    <span> View More</span>
                  </button>
                )}
              </div>
              <div className="search-input-open-box-right d-flex flex-column">
                <h3 className="search-input-open-box-heading mb-0">
                  Related Articles
                </h3>
                {isLoader ? (
                  <div className="text-center py-2">
                    <CircularProgress style={{ color: "#4f6367" }} />
                  </div>
                ) : (
                  <div>
                    {blogs && blogs.length > 0 ? (
                      blogs.slice(0, 3).map((item, index) => (
                        <Link
                          to={`/blog-details/${encryptId(item?.id)}`}
                          key={index}
                          className="recentlyblog"
                        >
                          <img
                            src={`${process.env.REACT_APP_CDN_URL}/${item?.featuredImagePath}`}
                            alt={item?.title || ""}
                          />
                          <div className="recentlyblogcontent">
                            <h6>{item?.categories[0]?.name || ""}</h6>
                            <h3>{item?.title || ""}</h3>
                          </div>
                        </Link>
                      ))
                    ) : (
                      <h5>No Blogs</h5>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="container-1600">
          <div className={styles.container}>
            <div className="logo">
              <Link to="/">
                <img src={require("../../assets/images/optimalogo.png")} />
              </Link>
            </div>
            <div className="hamburger-super-parent">
              <div
                className={`hamburger ${isOpen ? "active" : ""}`}
                onClick={toggleNav}
                ref={dropdownRef}
              >
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
              <nav className={`navigation ${isOpen ? "active" : ""}`}>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/About">About</Link>
                  </li>
                  <li>
                    <Link to="/Products">Products</Link>
                  </li>
                  <li>
                    <Link to="/Healthhub">Health Hub</Link>
                  </li>
                  <li>
                    <Link to="/Collection">Collection Center</Link>
                  </li>
                </ul>

                <div className="login-mob-btn">
                  {token ? (
                    <Link
                      to={
                        role?.role == 1 ||
                        role?.role == 4 ||
                        role?.role == 5 ||
                        role?.role == 6
                          ? "/adminPanel"
                          : role?.role == 3
                          ? "/CustomerDashboard"
                          : "/practitionerdashboard"
                      }
                      className="login-btn"
                    >
                      Dashboard
                    </Link>
                  ) : (
                    <Link to="/Login" className="login-btn">
                      Login
                    </Link>
                  )}
                </div>
              </nav>
            </div>
            <div className={styles.desktopMenu}>
              <ul>
                <li
                  className={location.pathname === "/" ? "active" : ""}
                  aria-current="page"
                >
                  <Link to="/">Home</Link>
                </li>
                <li
                  className={location.pathname === "/About" ? "active" : ""}
                  aria-current="page"
                >
                  <Link to="/About">About Us</Link>
                </li>
                <li
                  className={location.pathname === "/Products" ? "active" : ""}
                  aria-current="page"
                >
                  <Link to="/Products">Products</Link>
                </li>
                <li
                  className={location.pathname === "/HealthHub" ? "active" : ""}
                  aria-current="page"
                >
                  <Link to="/HealthHub">Health Hub</Link>
                </li>
                <li
                  className={
                    location.pathname === "/Collection" ? "active" : ""
                  }
                  aria-current="page"
                >
                  <Link to="/Collection">Collection Center</Link>
                </li>
              </ul>
            </div>

            <div className={styles.buttons}>
              <div className="cart-bx notif" style={{ position: "relative" }}>
                {searchOpen ? (
                  <div className={styles.search}>
                    <div>
                      <input
                        style={{ cursor: "pointer" }}
                        type="text"
                        placeholder="Search"
                        value={searchVal}
                        onChange={(e) => handleChangeSearch(e.target.value)}
                        onClick={() => {
                          searchVal !== "" ? setToggleDiv(true) : null;
                        }}
                        autoComplete="off"
                      />
                      <i
                        className="fa-solid fa-close"
                        onClick={toggleSearch}
                      ></i>
                    </div>
                  </div>
                ) : (
                  <div className={styles.iconButton} onClick={toggleSearch}>
                    <i className="fa-solid fa-search"></i>
                  </div>
                )}
              </div>

              {role?.role == 3 ? (
                <div className="cart-bx notif" style={{ position: "relative" }}>
                  <Link
                    className={styles.iconButton}
                    to="/CustomerNotification"
                  >
                    <i className="fa-solid fa-bell"></i>
                    <span>{notificationCount}</span>
                  </Link>
                </div>
              ) : (
                <></>
              )}

              <div className="cart-bx" style={{ position: "relative" }}>
                <Link
                  className={styles.iconButton}
                  to=""
                  ref={dropdownRef1}
                  onClick={() => setCartVisible(!cartVisible)}
                >
                  <i className="fa-solid fa-cart-shopping"></i>
                  <span>{cartItems?.length}</span>
                </Link>
                <Cart isVisible={cartVisible} onClose={closeCart} />
              </div>

              {token ? (
                <Link
                  to={
                    role?.role == 1 ||
                    role?.role == 4 ||
                    role?.role == 5 ||
                    role?.role == 6
                      ? "/adminPanel"
                      : role?.role == 3
                      ? "/CustomerDashboard"
                      : "/practitionerdashboard"
                  }
                  className="login-btn"
                >
                  Dashboard
                </Link>
              ) : (
                <Link to="/Login" className="login-btn">
                  Login
                </Link>
              )}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
