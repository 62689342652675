function QuizPhysical({ data, setData, updateData }) {
  return (
    <div className="titleparaquiz text-center">
      <div className="quiz-title-head titleparaquiz">
        <h1>Lifestyle</h1>

        <div>
          <p>
            <p>
              Sharing information on the habits of your physical activity is
              crucial to assess health risks provide tailored advice and
              determine necessary interventions for personalized care.
            </p>
          </p>
        </div>
        <label>
          <h2 style={{ color: "var(--Slate", fontWeight: "500" }}>
            Physical Activity
          </h2>
        </label>
        <div className="formbtn-quiz d-flex justify-content-center">
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Sedentary"
              value="Sedentary"
              onClick={() =>
                updateData(["Oxidised_LDL_Check", "Hormone_Imbalance_Check"], 3)
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Sedentary">
              Sedentary
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Inactive"
              value="Inactive"
              onClick={() =>
                updateData(["Oxidised_LDL_Check", "Hormone_Imbalance_Check"], 0)
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Inactive">
              Inactive
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="PActive"
              value="PActive"
              onClick={() =>
                updateData(["Oxidised_LDL_Check", "Hormone_Imbalance_Check"], 2)
              }
            />
            <label className="btn btn-outline-primary" htmlFor="PActive">
              Sometimes active{" "}
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Sometimes"
              value="Sometimes"
              onClick={() =>
                updateData(["Oxidised_LDL_Check", "Hormone_Imbalance_Check"], 0)
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Sometimes">
              Active
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Very"
              value="Very"
              onClick={() =>
                updateData(["Oxidised_LDL_Check", "Hormone_Imbalance_Check"], 1)
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Very">
              Very Active
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizPhysical;
