// created at 03-01-2024
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllConnectedCustomers = createAsyncThunk(
    'CONNECTED_CUSTOMERS',
    async ({ token, pId }) => {
        try {
            let config = {
                headers: {
                    Authorization: token,
                },
            };
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/practitioner/get-connected/${pId}`,
                config);
            let data = response.data?.acceptedCustomers;
            return data;

        } catch (err) {
            throw err
        }
    }
)

const PractitionerGetConnectedCustomers = createSlice({
    name: "ALL_CONNECTEDCUSTOMERS",
    initialState: {
        allConnectedCustomers: [],
        custLoader: false,
        custErr: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(getAllConnectedCustomers.pending, (state) => {
                state.custLoader = true;
            })
            .addCase(getAllConnectedCustomers.fulfilled, (state, action) => {
                state.custLoader = false;
                state.allConnectedCustomers = action.payload;
            })
            .addCase(getAllConnectedCustomers.rejected, (state, action) => {
                state.custLoader = false;
                state.custErr = action.error.message;
            });

    },
});
export default PractitionerGetConnectedCustomers.reducer;
